import React from 'react';
import { Link } from 'react-router-dom';

import _ from 'underscore';
import config from '../config';

const twoDigits = (n) => {
	return parseInt(n) < 10 ? '0'+n : n;
}

const helpers = {
	urlTitles: {
		'tjodfraedi': 'Þjóðfræði',
		'sagnir': 'Sagnir',
		'hljodrit': 'Hljóðrit',
		'audio': 'Hljóðrit',
		'einstaklingar': 'Einstaklingar',
		'person': 'Einstaklingar',
		'stadir': 'Staðir',
		'location': 'Staðir',
		'sagnir_aevintyri': 'Sagnir og ævintýri',
		'sagnir': 'Sagnagrunnur',
		'legend': 'Sagnir',
		'aevintyri': 'Ævintýragrunnur',
		'sagnagerd': 'Sagnagerð / minni',
		'erindi': 'Lausavísur',
		'handahof': 'Lausavísa af handahófi',
		'bragarhaettir': 'Bragarhættir',
		'bragi': 'Bragi, óðfræðivefur',
		'tonlist': 'Tónlist',
		'vidtol': 'Viðtöl',
		'interview': 'Viðtöl',
		'vidburdir': 'Viðburðir',
		'vidburdir_timeline': 'Viðburðir',
		'vidburdir_listi': 'Viðburðir',
		'timalina': 'Tímalína',
		'efnisord': 'Efnisorð',
		'svipad': 'Svipað efni',
		'kort': 'Kort',
		'bref': 'Bréf',
		'orgel': 'Orgel',
		'thing': 'Orgel',
		'hopar': 'Hópar',
		'tenglar': 'Tenglar',
		'ljod': 'Ljóð',
		'leit': 'Leit',
		'skrar': 'Tengdar skrár',
		'baekur': 'Bækur/handrit',
		'handrit': 'Bækur og handrit',
		'tengsltonlist': 'Tengslanet tónlistar',
		'tengsltjodfraedi': 'Tengslanet þjóðfræði',
		'log': 'Lög',
		'laglinur': 'Laglínur',
		'medlimir': 'Meðlimir',
		'listi': 'Listi',
		'frumgagn': 'Frumgagn',
		'saveditems': 'Handraðinn',
		'form': 'Form',
		'safnmark': 'Safnmark',
		'frumgagn': 'Frumgagn',
		'myndir': 'Myndir',
		'sidur': 'Myndir'
	},

	routerUrls: {
		'einstaklingar': '/einstaklingar/',
		'person': '/einstaklingar/',

		'tjodfraedi': '/tjodfraedi/',
		'tjodfraedi_leit': '/tjodfraedi/leit/',
		'hljodrit': '/tjodfraedi/hljodrit/',
		'audio': '/tjodfraedi/hljodrit/',
		'tjodfraedi_einstaklingar': '/tjodfraedi/einstaklingar/',
		'sagnir': '/tjodfraedi/sagnir/',
		'legend': '/tjodfraedi/sagnir/',
		'sagnir_aevintyri': '/tjodfraedi/sagnir_aevintyri/',
		'folklore': '/tjodfraedi/sagnir_aevintyri/',
		'aevintyri': '/tjodfraedi/aevintyri/',
		'fairytale': '/tjodfraedi/aevintyri/',
		'stadir': '/stadir/',
		'location': '/stadir/',
		'sagnagerd': '/tjodfraedi/sagnagerd/',
		'erindi_old': '/erindi/',
		'erindi': '/bragi/erindi/',
		'handahof': '/bragi/handahof/',
		'bragi': '/bragi/',
		'stanza': '/bragi/erindi/',
		'bragarhaettir_old': '/bragarhaettir/',
		'bragarhaettir': '/bragi/bragarhaettir/',
		'kvaedi_old': '/tjodfraedi/kvaedi/',
		'kvaedi': '/bragi/ljod/',
		'ljod': '/bragi/ljod/',
		'book': '/tonlist/baekur/',
		'baekur': '/tonlist/baekur/',
		'efnisord': '/tjodfraedi/efnisord/',
		'bref': '/tjodfraedi/bref/',
		'form': '/tjodfraedi/form/',
		'safnmark': '/tjodfraedi/safnmark/',
		'frumgagn': '/tjodfraedi/frumgagn/',

		'tonlist': '/tonlist/',
		'tonlist_einstaklingar': '/tonlist/einstaklingar/',
		'hopar': '/tonlist/hopar/',
		'group': '/tonlist/hopar/',
		'vidtol': '/tonlist/vidtol/',
		'vidburdir': '/tonlist/vidburdir/',
		'vidburdir_timeline': '/tonlist/vidburdir/timalina/',
		'vidburdir_listi': '/tonlist/vidburdir/listi/',
		'event': '/tonlist/vidburdir/',
		'interview': '/tonlist/vidtol/',
		'orgel': '/tonlist/orgel/',
		'thing': '/tonlist/orgel/',
		'log': '/tonlist/log/',
		'laglinur': '/tonlist/laglinur/',
		'song': '/tonlist/log/',

		'leit': '/leit/',

		'saveditems': '/handradinn/',

		'hljodrit_embed': '/embed/hljodrit/',
		'sagnir_map_embed': '/embed/sagnir_map/'
	},

	types: {
		'legend': 'Sögn',
		'fairytale': 'Ævintýri',
		'sagnir_aevintyri': 'Sögn/ævintýri',
		'location': 'Staður',
		'person': 'Einstaklingur',
		'audio': 'Hljóðrit',
		'stanza': 'Lausavísa',
		'interview': 'Viðtal',
		'event': 'Viðburður',
		'thing': 'Orgel',
		'group': 'Hópur',
		'book': 'Bók/handrit',
		'song': 'Lag'
	},

	typesPlural: {
		'legend': 'Sagnir',
		'fairytale': 'Ævintýri',
		'sagnir_aevintyri': 'Sagnir/ævintýri',
		'location': 'Staðir',
		'person': 'Einstaklingar',
		'audio': 'Hljóðrit',
		'stanza': 'Lausavísur',
		'interview': 'Viðtöl',
		'event': 'Viðburðir',
		'thing': 'Orgel',
		'group': 'Hópar',
		'book': 'Bækur/handrit',
		'song': 'Lög'
	},

	sagnirRelationTypes: {
		'sendandi': 'Sendandi',
		'skrasetjari': 'Skrásetjari',
		'heimildarmadur': 'Heimildarmaður'
	},

	getPageRoot: (url) => {
		for (var key in helpers.routerUrls) {
			if (url.startsWith(helpers.routerUrls[key])) {
				return key;
			}
		}

		return false;
	},

	getPathWithTitles: (url) => {
		let urlFrags = _.compact(url.split('/'));

		let buildUrl = [];

		urlFrags.map((item, index) => {
			let itemTitle = helpers.urlTitles[item] || false;

			if (itemTitle) {
				buildUrl.push(itemTitle)
			}
		});

		return buildUrl.join(' / ');
	},

	metadataList: (data, url, idField, labelField, urlParam, lowerCase, linkTarget) => {
		return data && data.length > 0 ? data.map((item, index) => {
			let printLabel = (label) => label ? (lowerCase && index > 0 ? label.toLowerCase() : label) : '';

			return <React.Fragment key={index}>
				{
					index > 0 && index == data.length-1 &&
					<React.Fragment> og </React.Fragment>
				}
				{
					index > 0 && index < data.length-1 && data.length > 1 &&
					<React.Fragment>, </React.Fragment>
				}
				{
					url && <Link to={url+(urlParam ? '?'+urlParam+'='+item[idField] : item[idField])} target={linkTarget}>{_.isFunction(labelField) ? labelField(item) : printLabel(item[labelField])}</Link>
				}
				{
					!url && (_.isFunction(labelField) ? labelField(item) : printLabel((labelField ? item[labelField] : item)))
				}
			</React.Fragment>
		}) : null;
	},

	createLocationParentString: (parent) => _.map(parent, (item, index) => item.name).join(' / '),

	parseSearchParams: (paramStr) => {
		let params = {};

		paramStr = decodeURI(paramStr);

		if (paramStr.substr(0, 1) == '?') {
			paramStr = paramStr.substr(1);
		}

		if (paramStr.split('&').length > 0) {
			paramStr.split('&').forEach((item, i) => {
				if (item.split('=')[0]) {
					params[item.split('=')[0]] = item.split('=')[1];
				}
			});
		}

		return params;
	},

	searchParamString: (params) => {
		let searchParams = [];

		for (var param in params) {
			searchParams.push(param+'='+
				(Array.isArray(params[param]) ? (
					params[param].map((item) => item.id || item).join(',')
				) : params[param])
			);
		}

		return '?'+searchParams.join('&');
	},

	updateSearchParams: (paramStr, newParams, preservePage) => {
		let currentParams = helpers.parseSearchParams(paramStr);

		for (var param in newParams) {
			currentParams[param] = newParams[param];
		}

		let searchParams = [];

		for (var param in currentParams) {
			if (currentParams[param] != undefined && currentParams[param] != '') {
				searchParams.push(param+'='+
					(Array.isArray(currentParams[param]) ? (
						currentParams[param].map((item) => item.id || item).join(',')
					) : currentParams[param])
				);
			}
		}

		return '?'+searchParams.join('&');
	},

	routeBasePath: (path) => {
		let start = 3;

		let parts = path.split('/').slice(0, start);
		return parts.join('/');
	},

	isNumber: (n) => !isNaN(parseFloat(n)) && !isNaN(n - 0),

	formatDuration: (millis) => {
		var minutes = Math.floor(millis / 60000);
		var seconds = ((millis % 60000) / 1000).toFixed(0);
		return twoDigits(minutes)+':'+twoDigits(seconds);
	},

	formatDate: (date, onlyYear, isoDate, includeTime) => {
		let ret = date || '';

		let safeParseInt = (i) => {
			return Number.isNaN(parseInt(i)) ? '' : parseInt(i);
		}

		let months = [
			'janúar',
			'febrúar',
			'mars',
			'apríl',
			'maí',
			'júní',
			'júlí',
			'ágúst',
			'september',
			'október',
			'nóvember',
			'desember'
		]

		try {
			if (isoDate && date != null) {
				let d = new Date(date);

				ret = onlyYear ?
					d.getFullYear() :
					d.getDate()+'. '+months[d.getMonth()]+' '+d.getFullYear()+
					(includeTime ? ', '+twoDigits(d.getHours())+':'+twoDigits(d.getMinutes()) : '')
				;
			}
			else {
				if (date.indexOf('?') > -1) {
					let dateFrags = date.split('-');

					let year = dateFrags[0].substr(2, 2) == '??' ? (safeParseInt(dateFrags[0].substr(0, 2))+1)+'. öld' : dateFrags[0];
					let month = '';
					let day = '';

					if (dateFrags[1]) {
						month = dateFrags[1].indexOf('?') > -1 ? (dateFrags[2] && dateFrags[2].indexOf('?') != -1 ? '' : '[óþekkt] ') : months[safeParseInt(dateFrags[1])-1]+' ';
					}

					if (dateFrags[2]) {
						day = dateFrags[2].indexOf('?') > -1 ? '' : safeParseInt(dateFrags[2])+'. ';
					}

					ret = day+month+year;
				}
				else if (date.split('-').length > 0) {
					let d = date.split('-');

					ret = onlyYear ? safeParseInt(d[0]) || '' : (d[2] ? safeParseInt(d[2])+'. ' : '')+(d[1] ? months[safeParseInt(d[1])-1]+' ' : '')+safeParseInt(d[0]);
				}
			}
		}
		catch (e) {}

		return ret;
	},

	removeWhitespace: (str) => {
		return (str || '').replace(/\s/g, '');
	},

	trim: (str, maxLength) => {
		let div = document.createElement('div');
		div.innerHTML = str || '';
		str = div.textContent || div.innerText || '';
		return str ? (str.length > (maxLength || 300) ? str.substr(0, (maxLength || 300))+'...' : str) : '';
	},

	fetch: (url, callback, pageTitle, titleField, titleFunction) => {
		fetch(url)
			.then((result) => {
				if (result.status != 200) {
					callback({
						notfound: true
					});
				}
				return result.json();
			})
			.then((json) => {
				if (json.detail && json.detail == 'Not found.') {
					callback({
						notfound: true
					});

					return;
				}

				try {
					if (json.name || (titleField && json[titleField]) || titleFunction) {
						document.title = config.siteTitle+' | '+(
							json.name ? json.name :
							(titleField && json[titleField]) ? json[titleField] :
							titleFunction ? titleFunction(json) : ''
						);
					}
					else if (pageTitle) {
						document.title = config.siteTitle+' | '+pageTitle;
					}
				}
				catch(error) {
					document.title = config.siteTitle;
				}

				callback(json);
			});
	},

	isVideoFile: (fileName) => {
		return ['mp4', 'mov', 'wmv', 'webm', 'avi', 'flv', 'mkv', 'mts'].indexOf(fileName.toLowerCase().split('.').slice(-1)[0]) > -1;
	},

	notesToString: (notes) => {
		return notes.map(note => note.step+':'+note.alter+':'+note.octave).join(',')
	},

	stringToNotes: (s) => {
		return s ? s.split(',').map((item) => {
			let frags = item.split(':');

			return {
				step: frags[0],
				alter: parseInt(frags[1]),
				octave: parseInt(frags[2])
			}
		}) : [];
	},

	normalizeNotes: (notes) => {
		let octavesMap = {
			3: -12,
			4: 0,
			5: 12,
			6: 22
		}
		let baseNotes = ['C', '', 'D', '', 'E', 'F', '', 'G', '', 'A', '', 'B'];

		let encoded = notes.map(note => baseNotes.indexOf(note.step)+(note.alter)+octavesMap[note.octave]);

		let startValue = encoded[0];

		let normalized = encoded.map(note => note-startValue);

		//unified = [normalized[i] for i in range(len(normalized)) if (i==0) or normalized[i] != normalized[i-1]]

		let unified = [];

		for (let i = 0; i < normalized.length; ++i) {
			if (normalized[i] == normalized[i + 1]) continue
			unified.push(normalized[i])
		}

		return unified
	},

	formatStanzaText: (t) => {
		try {
			return t.replace(/^([0-9]+)\./gm, '<div class="stanza-verse-brake"></div><div class="stanza-new-verse">$1.</div>')
			.replace(/<br>([0-9]+)\./gm, '<div class="stanza-verse-brake"></div><div class="stanza-new-verse">$1.</div>')
		}
		catch (e) {
			return t;
		}
	}
}

export default helpers;
