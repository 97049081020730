import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PlayButton from './ui/PlayButton';
import LoadingBar from './ui/LoadingBar';
import ListFilter from './filters/ListFilter';
import PaginationButtons from './PaginationButtons';
import NoResults from './ui/NoResults';

import config from './../config';
import helpers from './../lib/helpers';

function PoemList(props) {
	const [data, setData] = useState(null);
	const [nextUrl, setNextUrl] = useState(null);
	const [previousUrl, setPreviousUrl] = useState(null);
	const [resultCount, setResultCount] = useState(0);
	const [loading, setLoading] = useState(true);

	const history = useHistory();

	useEffect(() => {
		if (!data) {
			fetchData();
		}
	});

	useEffect(() => {
		fetchData();
	}, [props.locationId, props.searchParams]);

	const fetchData = () => {
		setLoading(true);
		setData(null);

		let urlParams = [];

		if (props.authorId) {
			urlParams.push('author='+props.authorId);
		}

		if (props.searchParams) {
			let params = helpers.parseSearchParams(props.searchParams)

			if (params.nafn) {
				urlParams.push('name='+params.nafn)
			}

			if (params.efni) {
				urlParams.push('contents='+params.efni)
			}

			if (params.tegund) {
				urlParams.push('type='+params.tegund)
			}

			if (params.bragarhattur) {
				urlParams.push('meter='+params.bragarhattur)
			}

			if (params.hofundur) { // höfundur
				urlParams.push('author='+params.hofundur)
			}
	
			if (params.samsafn) { // samsafn
				urlParams.push('collection='+params.samsafn)
			}

			if (params.sida) {
				urlParams.push('limit='+config.pageSize);
				urlParams.push('offset='+(config.pageSize*(params.sida-1)))
			}
		}

		helpers.fetch(config.apiRoot+'/api/poem/?'+urlParams.join('&'), (json) => {
			setData(json.results);
			setLoading(false);
			setNextUrl(json.next);
			setPreviousUrl(json.previous);
			setResultCount(json.count);

			if (props.onResponse) {
				props.onResponse(json.results);
			}
		});
	}

	return (
		<div className={'position-relative '+(props.className || '')+(loading ? ' loading' : '')}>
			<LoadingBar />
			{
				props.enableFilter &&
				<ListFilter filters={[
					{
						label: 'Nafn',
						paramName: 'nafn',
						type: 'text'
					},
					{
						label: 'Vísutexti',
						paramName: 'efni',
						type: 'text'
					},
					{
						label: 'Bragarháttur',
						paramName: 'bragarhattur',
						type: 'select',
						selectOptions: {
							endpoint: 'meter',
							labelField: 'name',
							valueField: 'id'
						},
						options: {
							extraParams: ['limit=2000']
						},
						viewAllLink: helpers.routerUrls['bragarhaettir']
					},
					{
						label: 'Tegund',
						paramName: 'tegund',
						type: 'select',
						selectOptions: {
							endpoint: 'stanzatype',
							labelField: 'name',
							valueField: 'id'
						}
					},
					{
						label: window.l('Samsafn'),
						paramName: 'samsafn',
						type: 'select',
						selectOptions: {
							endpoint: 'collections',
							labelField: 'name',
							valueField: 'id'
						},
						options: {
							extraParams: ['type=6']
						}
					},
					{
						label: 'Höfundur',
						paramName: 'hofundur',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'persons',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith',
							listLabelFormatFunc: (item) => <div>
								{item.name}
								{
									(item.date_of_birth || item.date_of_death) &&
									<React.Fragment>
										<br/>
										<small>{helpers.formatDate(item.date_of_birth, true)+'–'+helpers.formatDate(item.date_of_death, true)}</small>
									</React.Fragment>
								}
							</div>,
							enableTextInput: false
						}
					}
				]}
				currentParams={props.searchParams}
				onFilter={(event) => {
					history.push(history.location.pathname+helpers.updateSearchParams(props.searchParams, event));
				}} />
			}
			{
				data && data.length > 0 &&
				<React.Fragment>
					{
						props.children
					}
					<table className="table">
						<thead>
							<tr>
								<th>Heiti</th>
								<th>Höfundur</th>
								<th>Safn</th>
							</tr>
						</thead>
						<tbody>
							{
								data.map((item, index) => <tr key={index}>
									<td>
										<Link to={helpers.routerUrls['kvaedi']+item.id}>
											{
												item.name
											}
										</Link>
									</td>
									<td>
										{
											helpers.metadataList(item.author ? [item.author] : item.stanza_author.filter(item => !item.translator), helpers.routerUrls['einstaklingar'], 'id', 'name')
										}
									</td>
									<td>
										{
											helpers.metadataList(item.stanza_collections, helpers.routerUrls['kvaedi']+'?samsafn=', 'id', 'name')
										}
									</td>
								</tr>)
							}
						</tbody>
					</table>
					{
						resultCount > config.pageSize &&
						<PaginationButtons resultCount={resultCount}
							previousUrl={previousUrl}
							nextUrl={nextUrl} />
					}
				</React.Fragment>
			}
			{
				data && data.length == 0 && !loading &&
				<NoResults />
			}
		</div>
	)
}

export default PoemList;
